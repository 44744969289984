import { useEffect, useState } from 'react';

import './App.scss';
import Interaction from './Interaction';
import { InteractionConfig, validateConfig } from './types';

let askedForConfig = false;

export default function App() {
	const [loaded, setLoaded] = useState(false);
	const [showInteraction, setShowInteraction] = useState(false);
	const [config, setConfig] = useState<InteractionConfig | null>(null);

	useEffect(() => {
		if (loaded) window.parent?.postMessage({ status: 'readyToPlay' }, '*');
	}, [loaded]);

	const onInteractionError = (e: Error) => {
		console.error('error preloading');
		window.parent.postMessage({ status: 'error', error: e }, '*');
	};

	const onInteractionDone = () => {
		setShowInteraction(false);
		setTimeout(() => window.parent?.postMessage({ status: 'ended' }, '*'), 300);
	};

	useEffect(() => {
		const onmessage = (e: MessageEvent<{
			cmd: string;
			config: InteractionConfig;
			volume?: number;
			mirrored?: boolean
		}>) => {
			console.log('got message from parent', e.data.cmd);
			try {
				if (e.data.cmd === 'config') {
					console.log('setting config props', e.data.config);
					const configProps = {
						...e.data.config,
						volume: e.data.volume,
						mirrored: e.data.mirrored
					};
					if (!validateConfig(configProps)) {
						throw new Error('invalid config');
					}
					setConfig(configProps);
					return;
				}

				if (e.data.cmd === 'play') {
					if (!config) {
						throw new Error('no config when playing');
					}

					setShowInteraction(true);
					window.parent?.postMessage({ status: 'playing' }, '*');
				}
			} catch (error) {
				console.error('error handling message, posting to iframe');
				window.parent.postMessage({ status: 'error', error: error }, '*');
			}
		};
		window.addEventListener('message', onmessage);
		return () => {
			window.removeEventListener('message', onmessage);
		};
	}, [config]);

	// Tell the window that we are ready to config. This var state is persisted for the lifetime of the site.
	if (!askedForConfig) {
		askedForConfig = true;
		window.parent.postMessage({ status: 'readyToConfig' }, '*');
	}

	return (
		<div className="absolute-fill">
			<ol className={'center'}>
				{config && (
					<div className={'flex self-end ' + (!showInteraction ? 'opacity-0' : '')}>
						<Interaction config={config} shouldPlay={showInteraction} onLoaded={() => setLoaded(true)}
												 onDone={onInteractionDone} onError={onInteractionError} />
					</div>
				)}
			</ol>
		</div>
	);
}
